import {
  format,
  getMonth,
  getYear,
  isEqual,
  isValid,
  parseISO,
} from 'date-fns';
import { SiteLocales } from '../../../../types';
import { getDateFnsLocale } from '../../../../helpers/getDateFnsLocale';
import { DAY_MONTH, MONTH_DAY } from '../../../../constants';

export function isDateValid(date: Date | number | string | null) {
  return !!date && isValid(date instanceof Date ? date : new Date(date));
}

export function getTimezoneOffset(date: Date) {
  return date.getTimezoneOffset() * 60000;
}

export function convertDateToISOString(date: Date | null, ISOFormat = true) {
  if (!date || !isDateValid(date)) {
    return null;
  }

  const dateString = new Date(
    date.getTime() - getTimezoneOffset(date),
  ).toISOString();

  return ISOFormat ? dateString : dateString.slice(0, 10);
}

export const convertISOStringToDate = (date: string | null) => {
  if (!date || !isDateValid(date)) {
    return null;
  }

  const outputDate = new Date(date);
  return new Date(outputDate.getTime() + getTimezoneOffset(outputDate));
};

export interface GetCoursePricingOptionDatesParams {
  startDate?: string;
  endDate?: string;
  locale?: string;
  dateFormat?: typeof DAY_MONTH | typeof MONTH_DAY;
}

export function getFormattedDates({
  startDate,
  endDate,
  locale = SiteLocales.en,
  dateFormat = DAY_MONTH,
}: GetCoursePricingOptionDatesParams) {
  if (!startDate) {
    return;
  }

  const formatOptions = {
    locale: getDateFnsLocale(locale),
  };

  const start = parseISO(startDate);
  const end = endDate && parseISO(endDate);

  const isEndDate = end && !isEqual(start, end);
  let startFormat = isEndDate ? 'dd MMM' : 'dd MMM yyyy';

  if (dateFormat === MONTH_DAY) {
    startFormat = isEndDate ? 'MMM dd' : 'MMM dd, yyyy';
  }

  let formattedStart = format(start, startFormat, formatOptions);
  let formattedEnd = '';

  if (isEndDate) {
    const startMonth = getMonth(start);
    const endMonth = getMonth(end);
    const startYear = getYear(start);
    const endYear = getYear(end);

    if (dateFormat === MONTH_DAY) {
      if (startYear !== endYear) {
        formattedStart = format(start, 'MMM dd, yyyy', formatOptions);
        formattedEnd = format(end, 'MMM dd, yyyy', formatOptions);
      } else if (startMonth === endMonth) {
        formattedStart = format(start, 'MMM dd', formatOptions);
        formattedEnd = format(end, 'dd, yyyy', formatOptions);
      } else {
        formattedEnd = format(end, 'MMM dd, yyyy', formatOptions);
      }
    } else {
      if (startYear !== endYear) {
        formattedStart = format(start, 'dd MMM yyyy', formatOptions);
        formattedEnd = format(end, 'dd MMM yyyy', formatOptions);
      } else if (startMonth === endMonth) {
        formattedStart = format(start, 'dd', formatOptions);
        formattedEnd = format(end, 'dd MMM yyyy', formatOptions);
      } else {
        formattedEnd = format(end, 'dd MMM yyyy', formatOptions);
      }
    }
  }

  return formattedEnd ? `${formattedStart} - ${formattedEnd}` : formattedStart;
}
